var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page1" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        [_c("div", { staticClass: "container" })],
        _c(
          "div",
          {
            staticClass: "searchWrapper",
            on: {
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                $event.preventDefault()
                _vm.pageNum = 1
                _vm.searchData()
              },
            },
          },
          [
            _c(
              "el-form",
              {
                staticClass: "demo-form-inline",
                attrs: { inline: true, "label-position": "right" },
              },
              [
                _c("div", { staticClass: "search_box_title" }, [
                  _vm._v(_vm._s(_vm.$t("searchModule.Query_Table"))),
                ]),
                _c("div", { staticClass: "col_box" }, [
                  _c(
                    "div",
                    { staticClass: "col_left" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("searchModule.Event_Name") } },
                        [
                          _c("el-input", {
                            attrs: {
                              filterable: "",
                              placeholder: "输入活动名称",
                            },
                            model: {
                              value: _vm.couponActivityName,
                              callback: function ($$v) {
                                _vm.couponActivityName =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "couponActivityName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("searchModule.Activity_status"),
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "" },
                              model: {
                                value: _vm.status,
                                callback: function ($$v) {
                                  _vm.status =
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                },
                                expression: "status",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "全部", value: "" },
                              }),
                              _c("el-option", {
                                attrs: { label: "未开始", value: 1 },
                              }),
                              _c("el-option", {
                                attrs: { label: "进行中", value: 2 },
                              }),
                              _c("el-option", {
                                attrs: { label: "已结束", value: 3 },
                              }),
                              _c("el-option", {
                                attrs: { label: "已下架", value: 4 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col_right" },
                    [
                      _c(
                        "AuthorityComponent",
                        {
                          attrs: {
                            ComponentName: "el-button",
                            permission: ["button.query"],
                            type: "primary",
                            icon: "el-icon-search",
                            loading: _vm.loading,
                          },
                          on: {
                            click: function ($event) {
                              _vm.pageNum = 1
                              _vm.searchData()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("button.search")))]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col_box_boder" }),
                _c("div", { staticClass: "col_box h44" }, [
                  _c(
                    "div",
                    { staticClass: "col_left" },
                    [
                      _c(
                        "AuthorityComponent",
                        {
                          attrs: {
                            ComponentName: "el-button",
                            permission: ["button.add"],
                            type: "primary",
                            icon: "el-icon-plus",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$router.push("/activityManagementadd")
                            },
                          },
                        },
                        [_vm._v("添加")]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "col_right mbd4" }),
                ]),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "tableWrapper bgFFF paddingB10" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                staticStyle: { width: "100%" },
                attrs: { data: _vm.tableData },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    type: "index",
                    label: _vm.$t("list.index"),
                    align: "center",
                    width: "48",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("searchModule.Event_Name"),
                    width: "",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.couponActivityName)),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "活动时间",
                    width: "115px",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(scope.row.startTime) +
                                "至" +
                                _vm._s(scope.row.endTime)
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "优惠券发行量", "show-overflow-tooltip": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.publishAmount))]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "领取量",
                    width: "",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("receiveAmountFilter")(
                                  scope.row.receiveAmount
                                )
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "使用量",
                    width: "",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("usedAmountFilter")(scope.row.usedAmount)
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("searchModule.Activity_status"),
                    width: "",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(_vm._f("status")(scope.row.status))),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("searchModule.Audit_status"),
                    width: "",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("auditStatus")(scope.row.auditStatus)
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "活动链接",
                    width: "140px",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "clipboard",
                                  rawName: "v-clipboard:copy",
                                  value: scope.row.url,
                                  expression: "scope.row.url",
                                  arg: "copy",
                                },
                                {
                                  name: "clipboard",
                                  rawName: "v-clipboard:success",
                                  value: _vm.onCopy,
                                  expression: "onCopy",
                                  arg: "success",
                                },
                              ],
                            },
                            [_c("p", [_vm._v(_vm._s(scope.row.url))])]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                !(
                  !_vm.buttons.view &&
                  !_vm.buttons.edit &&
                  !_vm.buttons.down &&
                  !_vm.buttons.conf &&
                  !_vm.buttons.examine
                )
                  ? _c("el-table-column", {
                      attrs: { label: "操作", width: "70" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-dropdown",
                                  {
                                    on: {
                                      command: function ($event) {
                                        return _vm.dropdownClick(
                                          $event,
                                          scope.$index,
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { padding: "0" },
                                        attrs: { type: "text", size: "small" },
                                      },
                                      [
                                        _vm._v(" 操作 "),
                                        _c("i", {
                                          staticClass: "el-icon-arrow-down",
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "el-dropdown-menu",
                                      {
                                        attrs: { slot: "dropdown" },
                                        slot: "dropdown",
                                      },
                                      [
                                        _c(
                                          "AuthorityComponent",
                                          {
                                            attrs: {
                                              ComponentName: "el-dropdown-item",
                                              permission: ["button.view"],
                                              command: "a",
                                            },
                                          },
                                          [_vm._v("查看")]
                                        ),
                                        scope.row.auditStatus == 0
                                          ? _c(
                                              "AuthorityComponent",
                                              {
                                                attrs: {
                                                  ComponentName:
                                                    "el-dropdown-item",
                                                  permission: ["button.edit"],
                                                  command: "b",
                                                },
                                              },
                                              [_vm._v("修改")]
                                            )
                                          : _vm._e(),
                                        scope.row.auditStatus == 0
                                          ? _c(
                                              "AuthorityComponent",
                                              {
                                                attrs: {
                                                  ComponentName:
                                                    "el-dropdown-item",
                                                  permission: [
                                                    "button.examine",
                                                  ],
                                                  command: "e",
                                                },
                                              },
                                              [_vm._v("审核")]
                                            )
                                          : _vm._e(),
                                        (scope.row.status == 1 ||
                                          scope.row.status == 2) &&
                                        scope.row.auditStatus == 1
                                          ? _c(
                                              "AuthorityComponent",
                                              {
                                                attrs: {
                                                  ComponentName:
                                                    "el-dropdown-item",
                                                  permission: ["button.down"],
                                                  command: "f",
                                                },
                                              },
                                              [_vm._v("下架")]
                                            )
                                          : _vm._e(),
                                        scope.row.auditStatus == 0
                                          ? _c(
                                              "AuthorityComponent",
                                              {
                                                attrs: {
                                                  ComponentName:
                                                    "el-dropdown-item",
                                                  permission: ["button.conf"],
                                                  command: "c",
                                                },
                                              },
                                              [_vm._v("模板配置")]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2120616978
                      ),
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c("div", { staticClass: "pagerWrapper" }, [
              _c(
                "div",
                { staticClass: "block" },
                [
                  _vm.total > 0
                    ? _c("el-pagination", {
                        attrs: {
                          "current-page": _vm.pageNum,
                          "page-size": _vm.pageSize,
                          layout: "total, prev, pager, next, jumper",
                          total: _vm.total,
                        },
                        on: { "current-change": _vm.handleCurrentChange },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }